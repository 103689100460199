<template>
  <v-row>
    <v-col cols="12" sm="2">
      <v-card dark color="accent" outlined class="pa-2" height="100%">
        <v-card-text>
          <v-row>
            <v-select
              :items="canalOptions"
              v-model="canalSelected"
              item-text="Nombre"
              item-value="CanalId"
              append-icon="keyboard_arrow_down"
              label="Canal"
              hint="Canal"
              persistent-hint
              hide-details
            ></v-select>
          </v-row>
          <br />
          <v-row>
            <v-select
              :items="periodoOptions"
              v-model="periodoSelected"
              item-text="NombreMes"
              item-value="FechaFin"
              append-icon="keyboard_arrow_down"
              label="Periodo"
              hint="Periodo"
              persistent-hint
              hide-details
            ></v-select>
          </v-row>
          <br />
          <v-row>
            <v-autocomplete
              :items="asociados"
              v-model="asociadoSelected"
              item-text="Nombre"
              item-value="AsociadoId"
              placeholder="Asociado"
              append-icon="keyboard_arrow_down"
              hint="Asociado"
              persistent-hint
              clearable
            ></v-autocomplete>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="10">
      <v-progress-linear indeterminate color="primary" v-if="processing"></v-progress-linear>
      <v-tabs>
         <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab>Gráfica</v-tab>
        <v-tab>Reporte</v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div ref="amchart" style="height: 45vh;"></div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-data-table :headers="headers" :items="chartData" :items-per-page="10">
                <template v-slot:item.Monto="{ item }">{{(item.Monto / item.Conteo) | numeric}}</template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-alert
        dense
        type="warning"
        text
        v-show="!processing && isChartEmpty"
      >No existe información para esta gráfica</v-alert>
    </v-col>
  </v-row>
</template>

<script>
import apiDashboard from "@/api/dashboards";
import apiHome from "@/api/home";
import apiEmpleado from "@/api/empleados";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themesAnimated);

export default {
  data() {
    return {
      processing: false,
      chartData: [],
      chart: null,
      canalSelected: null,
      periodoSelected: null,
      asociadoSelected: null,
      asociados:[],
      headers: [
        { text: "PERIDO", value: "Periodo" },
        { text: "ESTADO", value: "Estado" },
        { text: "TOTAL", value: "Conteo" },
      ],
      canalOptions:[]
    };
  },
  computed: {
    isChartEmpty() {
      return this.chartData.length === 0;
    },
    periodoOptions() {
      return this.$store.getters.periodos;
    },
    asociadoOptions() {
      return this.$store.getters.asociados.filter(
        (c) => c.CanalId == this.canalSelected
      );
    },
    corteId() {
      return this.$store.getters.user.corteId;
    }
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    generateChart(dataParam) {
      if (this.chart) {
        this.chart.dispose();
      }

      try {
        let chart = am4core.create(this.$refs.amchart, am4charts.XYChart);
        chart.colors.step = 5;
        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";
        chart.numberFormatter.numberFormat = "#.##";

        let data = [];
        let conceptos = [];
        var periodos = [];
        dataParam.forEach(function (row) {
          if (conceptos.indexOf(row.Estado) === -1) {
            conceptos.push(row.Estado);
          }
          if (periodos.indexOf(row.Periodo) === -1) {
            periodos.push(row.Periodo);
          }
        });

        periodos.forEach(function (d) {
          let rows = dataParam.filter((f) => f.Periodo === d);
          let newRow = {
            Periodo: d,
            Total: 0,
          };
          conceptos.forEach(function (c) {
            let dc = rows.filter((f) => f.Periodo === d && f.Estado === c);
            if (dc[0]) {
              newRow[c] = dc[0].Conteo;
            }
          });
          data.push(newRow);
        });

        chart.data = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Periodo";
        categoryAxis.title.text = "Periodo";
        categoryAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;
        valueAxis.renderer.inside = true;
        valueAxis.extraMax = 0.1;
        valueAxis.calculateTotals = true;

        conceptos.forEach(function (c) {
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "Periodo";
          series.dataFields.valueY = c;
          series.name = c;
          series.stacked = true;
          series.sequencedInterpolation = true;
          series.calculatePercent = true;
          series.columns.template.width = am4core.percent(80);
          series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";

          var serieLabelBullet = series.bullets.push(
            new am4charts.LabelBullet()
          );
          serieLabelBullet.locationY = 0.5;
          // serieLabelBullet.label.fill = am4core.color("#fff");

          series.legendSettings.itemValueText = "{valueY.totalPercent}%";

          var labelBullet = series.bullets.push(new am4charts.LabelBullet());
          labelBullet.label.text = "{valueY.totalPercent}%";
          labelBullet.locationY = 0.5;
          // labelBullet.label.fill = am4core.color("#fff");
        });

        // Create series for total
        var totalSeries = chart.series.push(new am4charts.ColumnSeries());
        totalSeries.dataFields.valueY = "Total";
        totalSeries.dataFields.categoryX = "Periodo";
        totalSeries.stacked = true;
        totalSeries.hiddenInLegend = true;
        totalSeries.columns.template.strokeOpacity = 0;

        var totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
        totalBullet.dy = -20;
        totalBullet.label.text = "{valueY.total}";
        totalBullet.label.hideOversized = false;
        totalBullet.label.fontSize = 18;
        totalBullet.label.background.fill = totalSeries.stroke;
        totalBullet.label.background.fillOpacity = 0.2;
        totalBullet.label.padding(5, 10, 5, 10);

        this.chart = chart;
      } catch (e) {
        // empty
      }
    },
    refresh() {
      if (this.periodoSelected) {
        this.processing = true;
        let param = {
          FechaFin: this.periodoSelected,
          AsociadoId: this.asociadoSelected,
          CanalID: this.canalSelected,
        };

        apiDashboard
          .getGetChartReclamacion(param)
          .then((response) => {
            this.chartData = response.data;
            this.generateChart(this.chartData);
            this.processing = false;
          })
          .catch((error) => {
            this.setError(error);
            this.processing = false;
          });
      }
    },
    getCanales() {

        apiHome.getCanalesExternosGestionados(this.corteId).then((response) => {
        this.canalOptions = response.data;
      });

    },
    async getAsociados(){
      
      const id = this.corteId?this.corteId:0;

      let param = {
        CanalesId:[this.canalSelected],
        GruposComisionId: [],
        CorteId:id
        };

      const response = await apiEmpleado.getAsociadosPorCanales(param);

      this.asociados = response.data
    },
  },
  watch: {
    corteId: function () {
      this.getCanales();
    },
    canalSelected: function () {
      this.getAsociados();
      this.refresh();
    },
    periodoSelected: function () {
      this.refresh();
    },
    asociadoSelected: function () {
      this.refresh();
    },
  },
  mounted() {
    if (this.canalOptions.length > 0) {
      this.canalSelected = this.canalOptions[0].CanalId;
    }
    if (this.periodoOptions.length > 0) {
      this.periodoSelected = this.periodoOptions[0].FechaFin;
    }

    this.refresh();
    this.getCanales();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
</style>